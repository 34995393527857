<template>
  <div class="container-Resgate">
        <!-- -->
      <section class="container-fluid">
        <section class="container" >
          <div class="row">
            <div class="col" id="topo-campanha"><br>
            <h1 style="color:#400041;" class="bold">Cadastro clientes Ticket</h1><br>
            </div>
            </div>
        </section>

        <div class="row">

          <div class="col-sm-12 fundo-roxo quadroPage">
            <br>
            <div class="espacamento txt-white" >
              
              
             
<DegustacaoCampanha />
              
           

            </div>
                 


               
          </div><!-- col -->

         

        </div>
      </section>






      <!-- -->
  </div>
</template>

<style>

.espacamento{padding:0 40px;}
.fundo-laranja{background:#F18800;padding-bottom:20px;}
.fundo-roxo{background:#400041;padding-bottom:20px;}
.fundo-roxo2{background:#764573;padding-bottom:20px;}
.quadroPage{min-height:900px;}
.txt-white{color:#FFF;}
</style>

<script>
import DegustacaoCampanha from '@/components/DegustacaoCampanha'

export default {
  name: 'CadastroCampanha',
  components: {DegustacaoCampanha},
  data(){
    return {
     
    }
  },
  methods:{
 
    abrirModal(){
     window.jQuery('#modal-degustacao').modal('show');
   }
  },
  computed:{
    
  },
  
  mounted(){
     this.$scrollTo("#topo-campanha");
this. abrirModal();
  }
}
</script>